/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
// https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8#
import { onError } from "@apollo/client/link/error";
import { fromPromise } from "@apollo/client/link/utils/fromPromise";

import constants from "../utils/constants";
import getRefreshToken from "./getRefreshToken";

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export default onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let error of graphQLErrors) {
        switch (error.code) {
          case 400001:
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                getRefreshToken()
                  .then((response) => {
                    const accessToken =
                      response?.data?.accountGenerateAccessToken?.accessToken;
                    localStorage.setItem(constants.ACCESS_TOKEN, accessToken);
                  })
                  .then(() => {
                    resolvePendingRequests();
                    return true;
                  })
                  .catch(() => {
                    // TODO: Log out user if the request fails and clear pending requests
                    localStorage.removeItem(constants.ACCESS_TOKEN);
                    pendingRequests = [];
                    return false;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  }),
              );
            } else {
              forward$ = fromPromise(
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve());
                }),
              );
            }

            return forward$.flatMap(() => forward(operation));
          case 401002: // TODO: Deprecate once all not logged in errors have moved to 401_004
          case 401004:
            if (!location.pathname.includes("/point-of-sale/login")) {
              localStorage.clear();
              if (
                !["/login", "/forgot-password", "/reset-password"].includes(
                  location.pathname,
                )
              ) {
                location.reload();
              }
            }
            return null;
          default: // Do nothing
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
);
